@media only screen and (min-width: 1440px) {
    .header{
        padding: .5rem 5rem;
        margin-right: auto;
        margin-left: auto;
    }
}

@media only screen and (max-width: 1440px) {
.header{
    border-bottom: 3px solid black;
    width: 100%;
    position: fixed;
    top: 0;
    background-color: white;
    padding: .5rem 2rem;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
    .header .navbar-light .navbar-nav .nav-link {
        margin-left: 0px;
    }

    .header .navbar, .header{
        padding-left: 0;
        padding-right: 0;
    }

    .header .navbar-collapse{
        position: relative;
        z-index: 1;
    }

    .navbar-collapse.collapsing {
        -webkit-transition: height 0.5s;
        -moz-transition: height 0.5s;
        -ms-transition: height 0.5s;
        -o-transition: height 0.5s;
        transition: height 0.5s;
      
      }
    .header .navbar-nav:before{
            content: '';
            position: relative;
            width: 100%;
            /* height: 3px; */
            background: black;
            top: 0%;
            left: 0%;
    }
    .silhouette img{
        width: 120px;
        z-index: -1;
      }

    .home .maintenance{
        width: 95%;
    }

    .about .row{
        font-size: 18px;
      }
    .about h1{
        margin-bottom: 60px;
    }

    @keyframes collapse {
        0% {
          visibility: hidden;
          height: 0px;
          opacity: 0;
        }
        40% {
          visibility: hidden;
          height: 0;
          opacity: 0;
        }
        100% {
          visibility: visible;
          /* height: 225px; */
          height: 185px;
          opacity: 1;
        }
      }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
    .home .maintenance{
        width: 90%;
        font-size: 18px;
    }

    .about h1{
        font-size: 30px;
        margin-bottom: 50px;
    }
    .about .row{
        font-size: 16px;
      }

      @keyframes collapse {
        0% {
          visibility: hidden;
          height: 0px;
          opacity: 0;
        }
        40% {
          visibility: hidden;
          height: 0;
          opacity: 0;
        }
        100% {
          visibility: visible;
          height: 135.6px;
          /* height: 185px; */
          opacity: 1;
        }
      }

    .products .MuiCollapse-entered{
        transition: linear;
        margin-top: -150px;
    }

    .products .detailed-specs h1{
        font-size: 32px;
    }

    .products .detailed-specs h5{
        font-size: 16px;
    }

    .products .detailed-specs p{
        font-size: 14px;
    }

    .products .detailed-specs .buyNow{
        width: 50px;
    }
    
      
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 767px) {
    .header .navbar .container{
        padding: .5rem 1rem;
    }
    .header .navbar-light .navbar-nav .nav-link {
        font-size: 22px;
    }
    .header img{
        height: 40px;
      }
      
    .footerBottom .container{
        font-size: 20px;
    }

    .footerBottom .brand{
        padding-top: 10px;
        margin: 0 0 5px 0;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 2px;
    }

    .footerBottom .copyright{
        font-size: 7px;
        font-weight: 900;
        margin-top: 15px;
    }
    .silhouette img{
        width: 80px;
      }

    .home .maintenance{
        width: 90%;
        font-size: 14px;
    }
    
    .home .button{
        width: 124px;
      }
      
      .home .button a{
        font-size: 14px;
        padding: 10px 30px;
      }

      .about h1{
        font-size: 38px;
        margin-bottom: 50px;
    }

    .about p{
        margin-top: 20px;
    }

    .about .row .button{
        width: 156px;
        margin: auto;
      }

            .contact{
        font-size: 18px;
        margin-top: 150px;
        margin-bottom: -300px;
      }
      
      .contact .page-full-wrap{
        width: 95%;
      }
      
      .contact .squares{
        height: 55px;
      }
      
      .contact .leftSide{
        left: 8px;
      }
      
      .contact .leftSide img{
        width: 40px;
      }
      
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 480px) {
    .header .navbar-light .navbar-nav .nav-link {
        font-size: 18px;
    }
    .header img{
        height: 40px;
      }
    .footerBottom .container{
        font-size: 20px;
    }

    .footerBottom .brand{
        padding-top: 10px;
        margin: 0 0 5px 0;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 2px;
    }

    .footerBottom .copyright{
        font-size: 7px;
        font-weight: 900;
        margin-top: 15px;
    }

    .home .maintenance{
        width: 90%;
        font-size: 14px;
    }
    
    .home .button{
        width: 124px;
      }
      
      .home .button a{
        font-size: 14px;
        padding: 10px 30px;
      }
      @keyframes collapse {
        0% {
          visibility: hidden;
          height: 0px;
          opacity: 0;
        }
        40% {
          visibility: hidden;
          height: 0;
          opacity: 0;
        }
        100% {
          visibility: visible;
          height: 253.5px;
          /* height: 185px; */
          opacity: 1;
        }
      }

    .products .MuiCollapse-entered{
        transition: linear;
        margin-top: -280px;
    }

    .products .detailed-specs h1{
        font-size: 32px;
    }

    .products .detailed-specs h5{
        font-size: 16px;
    }

    .products .detailed-specs p{
        font-size: 14px;
    }

    .products .detailed-specs .buyNow{
        width: 50px;
    }

    .products .content{
        text-align: center;
    }

    .products .togglerButton .collapsedImg, 
    .products .togglerButton .expandedImg{
        width: 20px;
    }

    .products .hr.col-sm-5{
        width: 100%;
    }

    .products .content .title{
        font-weight: 500;
        font-size: 26px;
        padding: 5px 0 5px 0;
      }
      .products .content .variation{
        font-size: 18px;
      }
      
      .products .content .price{
        font-weight: 500;
        font-size: 22px;
      }

    .products .detailed-specs{
        margin-top: 25px;
    }

    

      .contact{
        font-size: 18px;
        margin-top: 150px;
        margin-bottom: -150px;
      }
      
      .contact .page-full-wrap{
        width: 95%;
      }
      
      .contact .squares{
        padding-left: 50px;
        height: 55px;
      }
      
      .contact .leftSide{
        left: 8px;
      }
      
      .contact .leftSide img{
        width: 40px;
      }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 320px) {}
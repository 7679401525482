@font-face {
  font-family: Quicksand;
  src: url(./fonts/Quicksand-Light.ttf);
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: Quicksand;
  src: url(./fonts/Quicksand-Regular.ttf);
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: Quicksand;
  src: url(./fonts/Quicksand-Medium.ttf);
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: Quicksand;
  src: url(./fonts/Quicksand-SemiBold.ttf);
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: Quicksand;
  src: url(./fonts/Quicksand-Bold.ttf);
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: SourceSansPro;
  src: url(./fonts/SourceSansPro-Black.ttf);
  font-style: normal;
  font-weight: 900;
}



*{
  font-family: Quicksand;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.ta-center{
  text-align: center;
}

.ta-right{
  text-align: right;
}

.ta-left{
  text-align: left;
}

/* HEADER */

.header{
  border-bottom: 3px solid black;
  width: 100%;
  position: fixed;
  top: 0;
  background-color: white;
  padding: .5rem 15rem;
  z-index: 1;
}

.header img{
  height: 60px;
}

.fa-bars {
  font-size: 26px;
}

.header .navbar-light .navbar-nav .nav-link {
  outline: none;
  font-size: 26px;
  font-weight: 500;
  color: rgba(1, 1, 1, 1);
  margin-left: 35px;
}

.header .navbar-light .navbar-nav .active {
  font-size: 26px;
  font-weight: 700; 
  color: rgba(1, 1, 1, 1);
}

.header .navbar-light .navbar-toggler {
  outline: none;
  color: rgba(1, 1, 1, 1) !important;
  border-color: rgba(0, 0, 0, 0) !important;
}




/* FOOTER */

.footerBottom{
    background-color:black ;
    height: 145px;
}

.footerBottom .container{
    font-family: Quicksand;
    font-weight: 600;
    color: white;
    text-align: center;
    font-size: 26px;
}

.footerBottom .brand{
    padding-top: 10px;
    margin: 0 0 5px 0;
    font-size: 26px;
    font-weight: 700;
    letter-spacing: 2px;
}

.footerBottom .socials{
    margin: auto;
}
.footerBottom .socials svg{
  cursor: pointer;
}

.footerBottom .socials .white{
    color: "#FFFFF";
    margin: 0 6px 0 6px;
}

.footerBottom .copyright{
    font-family: SourceSansPro;
    font-size: 10px;
    font-weight: 900;
    margin-top: 15px;
}

/* CONTENT */


.silhouette{
  position: relative;
  margin-top: 300px;
}

.silhouette img{
  width: 150px;
}

.silhouette .img{
  position:absolute;
  bottom:0;
  right:0;
}

/* HOME */

.home{
  margin-top: 250px;
}

.home .maintenance{
  width: 75%;
  margin: auto;
  font-weight: bold;
  font-size: 20px;
}

.home .maintenance a{
  text-decoration: none;
  outline: none;
  color: black;
}


.home .button{
  width: 140px;
  margin: auto;
}

.home .button a{
  text-decoration: none;
  outline: none;
  background-color: black;
  color: white;
  padding: 12px 32px;
  font-weight: bold;
  border-radius: 8px;
  margin: auto;
}

/* ABOUT */

.about{
  margin-top: 150px;
  text-align: center;
}

.about h1{
  margin-bottom: 100px;
}

.about .about-img{
  padding: 2%;
  padding-bottom: 0;
  width: 100%;
  border: 3px solid black;
}

.about .row{
  /* width: 75%; */
  margin: auto;
  font-weight: normal;
  font-size: 20px;
  text-align: justify;
}

.about .row .button{
  width: 100%;
}

.about .row .button a{
  text-decoration: none;
  outline: none;
  background-color: black;
  color: white;
  padding: 12px 32px;
  font-weight: bold;
  border-radius: 8px;
}

/* PRODUCTS */

.products{
  margin-top: 150px;
}

.products .product-img{
  width: 100%;
}

.products .content{
  margin: auto;
}

.products .content .title{
  font-weight: 500;
  font-size: 32px;
  padding: 5px 0 5px 0;
}
.products .content .variation{
  font-size: 24px;
}

.products .content .price{
  font-weight: 500;
  font-size: 28px;
}

.products div.visible{
  animation: collapse 1s linear;
}

.products .hidden{
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.5s, opacity 0.5s linear;
}

@keyframes collapse {
  0% {
    visibility: hidden;
    height: 0px;
    opacity: 0;
  }
  40% {
    visibility: hidden;
    height: 0;
    opacity: 0;
  }
  100% {
    visibility: visible;
    height: 225px;
    /* height: 185px; */
    opacity: 1;
  }
}

.products .MuiCollapse-hidden{
  transform: scaleY(1);
  transform-origin: 100% 100%;
  transition-property: transform;
  transition: 100ms ease-out;
  height: 0px;
  transition: linear;
}

.products .MuiCollapse-entered{
  transition: linear;
  margin-top: -185px;
}

.products .productImgs{
}

.products .top-img{
  width: 100%;
  height: 90%;
  border: 3px solid black;
  object-fit: contain;
}

.products .top-img .img-big{
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: auto;
}

.products .bot-img{
  object-fit: contain;
  margin: auto;
  display: table-cell;
}


.products .bot-img .img-small{
  border: 2px solid black;
  border-top: none;
  cursor: pointer;
  width: 16.667%;
}

.products .bot-img .img-small:nth-child(2){

}
.products .bot-img .img-small:nth-child(3),
.products .bot-img .img-small:nth-child(4),
.products .bot-img .img-small:nth-child(5)
{
}
.products .bot-img .img-small:last-child{
  border-right: 3px solid black;
}

.products .bot-img .img-small:first-child{
  border-left: 3px solid black;
}

.products .hr{
  border: 1px solid black;
  background-color: black;
  height: 3px;
  width: 100%;
  margin: auto;
}

.products .collapseButton{
  cursor: pointer;
  text-align: center;
  margin: auto;
  margin-bottom: 25px;
  margin-top: 25px;
}

.products .togglerButton .collapsedImg{
  width: 40px;
  transition: transform 1s;
}

.products .togglerButton .expandedImg{
  width: 40px;
  transition: transform 1s;
  transform: scaleY(-1);
}

.products input{
  outline: none;
}

.products .custom-select{
  cursor: pointer;
  width: auto;
  color: white;
  font-weight: bold;
  background-color: black;
  text-align: center;
  outline: none;
  text-decoration: none;
  box-shadow: none !important;
}

.products .custom-select option{
  cursor: pointer;
  width: auto;
  color: white;
  font-weight: bold;
  background-color: black;
  text-align: center;
}

.products .buyNow{
  cursor: pointer;
  width: 65px;
  border: solid black 3px;
  border-radius: .25rem;
  background-color: black;
}

/* CONTACT */

.contact{
  font-size: 24px;
  text-align: center;
  margin-top: 200px;
  margin-bottom: -300px;
}

.contact .page-full-wrap{
  position: relative;
  margin: auto;
  width: 75%;
  padding-bottom: 176px;
}

/* .contact .buyNow{
  cursor: pointer;
  width: 40%;
  border: solid black 3px;
  border-radius: .25rem;
  background-color: black;
} */

.contact .squares{
  margin: auto;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  border-radius: .25rem;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  /* width: 80%; */
  height: 80px;
}

.contact .squares:hover{
  cursor:pointer;
  transform: translate3d(0px, 0px, 0px) scale(1.015);
}

.contact .leftSide{
  position: absolute;
  left: 15px;
}

.contact .leftSide img{
  width: 65px;
}

.contact .rightSide{
  text-align: center;
}

/* WARRANTY */

.warranty{
  font-size: 14px;
  text-align: left;
  margin-top: 200px;
  margin-bottom: -300px;
}

.warranty .title{
  text-align: center;
  font-size: 24px;
}
.warranty .subtitle{
  text-align: center;
  font-size: 18px;
}

.warranty .page-full-wrap{
  position: relative;
  margin: auto;
  width: 85%;
  padding-bottom: 176px;
}


.warranty .squares{
  text-align: center;
  margin: auto;
  font-size: 24px;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  border-radius: .25rem;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  height: 80px;
  width: auto;
}

.warranty .squares:hover{
  cursor:pointer;
  transform: translate3d(0px, 0px, 0px) scale(1.015);
}

.warranty .rightSide{
  text-align: center;
}

/* MANUALS */
.manuals{
  font-size: 24px;
  text-align: center;
  margin-top: 200px;
  margin-bottom: 100px;
}

.manuals .video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.manuals .video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.manuals .button a{
  cursor: pointer !important;
  text-decoration: none;
  outline: none;
  background-color: black;
  color: white !important;
  padding: 12px 32px;
  font-weight: bold;
  border-radius: 8px;
}

.manuals .page-full-wrap{
  position: relative;
  margin: auto;
  width: 75%;
  padding-bottom: 176px;
}

.manuals .squares{
  margin: auto;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  border-radius: .25rem;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  /* width: 80%; */
  height: 80px;
}

.manuals .squares:hover{
  cursor:pointer;
  transform: translate3d(0px, 0px, 0px) scale(1.015);
}

.manuals .leftSide{
  position: absolute;
  left: 15px;
}

.manuals .leftSide img{
  width: 65px;
}

.manuals .rightSide{
  text-align: center;
}